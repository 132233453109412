<template>
    <b-row>
        <b-col :class="!pageSelected ? 'icons' : ''">
            <template v-if="pageSelected">
                <router-view/>
            </template>
            <template v-else>
                <b-card-group deck>
                    <icon-link prefix="fas"
                               icon="info-circle"
                               title="Release Notes"
                               :to="{name:'releaseNotes'}" />
                    <icon-link prefix="fas"
                               icon="bell"
                               title="User Notifications"
                               :to="{name:'userNotifications'}" />
                    <icon-link prefix="fas"
                               icon="book"
                               title="Help Manual"
                               to="/helpdocs"
                               target="_blank"/>
                    <icon-link prefix="far"
                               icon="play-circle"
                               title="Training Videos"
                               :to="{name:'trainingVideos'}"/>
<!--                    <icon-link prefix="far"-->
<!--                               icon="comment-dots"-->
<!--                               title="Submit Feedback"-->
<!--                               :to="{name: 'feedback'}" v-if="false"/>-->
<!--                    <icon-link prefix="fas"-->
<!--                               icon="exclamation-circle"-->
<!--                               title="Notices"-->
<!--                               :to="{name:'notices'}" v-if="false"/>-->
                </b-card-group>
            </template>
        </b-col>
    </b-row>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import IconLink from '@/views/private/shared/IconLink';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import _ from 'underscore';

function isPageSelected(pageName) {
    return _.contains([
        'releaseNotes',
        'userNotifications',
        'notices',
        'trainingVideos',
        'feedback'
    ], pageName);
}

function routeHandler(to, from, next) {
    const BASE_CRUMBS = [
        Breadcrumb.create('Administration', {name: 'adminMain'}, false),
        Breadcrumb.create('Help', {name: 'help'}, !isPageSelected(to.name))
    ];

    store.commit('setBreadcrumbs', _.clone(BASE_CRUMBS));
    next();
}



@Component({
    components: {
        IconLink
    }
})
export default class HelpView extends Vue {

    get pageSelected() {
        return isPageSelected(this.$route.name);
    }

    beforeRouteEnter(to, from, next) {
        routeHandler(to, from, next);
    }

    beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }
}
</script>
<style scoped>

</style>